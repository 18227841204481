























































































import Vue from 'vue';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import * as toast from '@/shared/configs/toasts/toasts.config';

export default Vue.extend({
  data () {
    return {
      pendingRequest: false,
      showPassword: false,
      errorMessage: '',
      tosAgreed: false,
      registerForm: {
        email: '',
        password: null,
        passwordRepeat: null,
        name: ''
      }
    };
  },

  methods: {
    // ================================================================================
    // Events
    // ================================================================================

    onTogglePasswordVisibility (): void {
      this.showPassword = !this.showPassword;
    },

    onLoginRequested (): void {
      this.$router.push({ name: 'Authentication:Login' });
    },

    async onRegister (): Promise<void> {
      if (this.pendingRequest) { return; }

      try {
        this.pendingRequest = true;
        this.errorMessage = '';
        await this.$store.dispatch('auth/register', this.registerForm);
        await this.$router.replace({ name: 'App:Dashboard' });
      } catch (e) {
        switch (e.code) {
          case 'auth/email-already-in-use':
            this.errorMessage = toast.messages.auth.register.error.userExist;
            break;
          case 'auth/invalid-email':
            this.errorMessage = toast.messages.auth.register.error.invalidEmail;
            break;
          case 'auth/invalid-credentials':
            this.errorMessage = toast.messages.auth.register.error.invalidCredentials;
            break;
          case 'user-cannot-be-retrieved':
            this.errorMessage = '';
            break;
          default:
            this.errorMessage = toast.messages.common.error;
            break;
        }
      } finally {
        this.pendingRequest = false;
      }
    }
  },

  // ================================================================================
  // Validations
  // ================================================================================
  validations: {
    registerForm: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      passwordRepeat: { required, minLength: minLength(6), sameAsPassword: sameAs('password') }
    }
  }
});
